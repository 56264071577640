import { IAction } from 'actions/commonAction';
import { ICounty } from 'api/generated/models';
import {
    CLEAR_ADDRESS_INPUTS,
    UPDATE_ADDRESS_INPUTS,
    VALIDATE_ADDRESS_INPUTS,
} from 'components/addressInput/addressInputActions';
import { IFormErrors } from 'utilities/forms';
import { object, string } from 'yup';

export type IAddressInputs = {
    addressId: string | undefined;
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    countyFips: string;
    state: string;
    zip: string;
};

const ZIP_CODE_LENGTH = 5;
const FIFTY = 50;
const TEN = 10;
const PO_BOX_MESSAGE =
    'The address you entered is a P.O. Box. Please enter the address for your primary residence.';

const isNotPoBox = (address: string) => {
    const normalizedAddress = address.toUpperCase().replace(/[\s.-]/g, '');
    return !normalizedAddress.includes('POBOX');
};

export const getSchema = (counties: ICounty[], validatePOBoxAddress: boolean) =>
    object({
        addressLine1: string()
            .trim()
            .required()
            .max(FIFTY)
            .test('isPoBox', PO_BOX_MESSAGE, (value) =>
                validatePOBoxAddress && value ? isNotPoBox(value) : true
            )
            .label('Address'),
        addressLine2: string()
            .trim()
            .max(FIFTY)
            .test('isPoBox', PO_BOX_MESSAGE, (value) =>
                validatePOBoxAddress && value ? isNotPoBox(value) : true
            )
            .label('Address Line 2'),
        city: string()
            .trim()
            .required()
            .max(FIFTY)
            .label('City'),
        countyFips: string()
            .trim()
            .required()
            .test(
                'areNoCountyMatches',
                'Invalid state, zip, and county combination',
                (value, context) =>
                    counties.some((x) => x.fips === value && x.stateAbbrev === context.parent.state)
            )
            .max(TEN)
            .label('County'),
        state: string()
            .trim()
            .required()
            .test(
                'areNoStateMatches',
                'Invalid state and zip code combination',
                (_value, context) => counties.some((x) => x.stateAbbrev === context.parent.state)
            )
            .max(FIFTY)
            .label('State'),
        zip: string()
            .trim()
            .required()
            .length(ZIP_CODE_LENGTH)
            .test('emptyCounties', 'Invalid zip code', () => counties.length > 0)
            .label('Zip Code'),
    });

export type IAddressInputState = {
    addressInputs: IAddressInputs;
    errors?: IFormErrors<ReturnType<typeof getSchema>>;
};
const initialState: IAddressInputState = {
    addressInputs: {
        addressId: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        county: '',
        countyFips: '',
        state: '',
        zip: '',
    },
};

export const addressInputState = (
    state = initialState,
    action: IAction<IAddressInputState>
): IAddressInputState => {
    switch (action.type) {
        case CLEAR_ADDRESS_INPUTS:
            return initialState;
        case VALIDATE_ADDRESS_INPUTS:
        case UPDATE_ADDRESS_INPUTS:
            return {
                ...state,
                ...action.data,
            };
    }
    return state;
};
