import { Chip } from '@mui/material';
import { IUser } from 'api/generated/models';
import { EditUser } from 'api/generated/permissions';
import ConditionalTooltip from 'components/ConditionalTooltip';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import styled from 'styled-components';

const TootlipContainer = styled.div`
    white-space: pre-line;
    overflow-wrap: break-word;
    text-align: left;
`;

const HasDummyEmail = (user: IUser | undefined): boolean => !!user?.hasDummyEmail;

const InvalidEmailPill = ({ user }: { user: IUser | undefined }) => {
    const { canEditUsers } = useSelector((state: AppStore) => ({
        canEditUsers: hasSomePermissions(state, EditUser),
    }));

    if (!canEditUsers || !HasDummyEmail(user)) {
        return <React.Fragment />;
    }

    const tooltipContent = (
        <TootlipContainer>
            This member was added via HRIS integration and did not have a valid email in the HRIS.
            Please add a valid email to the HRIS system and wait 24 hours for a sync or update the
            member&apos;s email.
        </TootlipContainer>
    );

    return (
        <ConditionalTooltip isDisabled={false} title={tooltipContent}>
            <Chip color="error" label="Invalid Email" size="small" sx={{ ml: 1 }} />
        </ConditionalTooltip>
    );
};

export default hot(module)(InvalidEmailPill);
