import TeamManagementContext from 'contexts/TeamManagementContext';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import { hasValue } from 'utilities';
import EventEmitter from 'utilities/eventEmitter';

const GradientIndicator = styled.div(
    ({
        includeTopOffset,
        isAtBottom,
        isShowingSidebar,
        shouldScroll,
        topOffset,
    }: {
        includeTopOffset: boolean;
        isAtBottom: boolean;
        isShowingSidebar: boolean | undefined;
        shouldScroll: boolean;
        topOffset: number;
    }) => `
    &:before,
    &:after {
        content: '';
        width: 100%;
        height: 60px;
        background: transparent;
        position: absolute;
    }
    &:before ${
        includeTopOffset
            ? `{
        top: ${topOffset}px;
        left: 0;
        background: linear-gradient(rgba(0,0,0,0.22), transparent);
    }`
            : ','
    }
    &:after {
        bottom: ${isShowingSidebar ? '61px' : 0};
        left: 0;
        ${shouldScroll &&
            !isAtBottom &&
            'background: linear-gradient(transparent, rgba(0,0,0,0.22));'}
    }
    &.top {
        &:before {
            display: none;
        }
    }
    &.bottom {
        &:after {
            display: none;
        }
    }
`
);

type IScrollGradientIndicator = {
    headerElementClassName?: string;
    includeTopOffset?: boolean;
    scrollElementId?: string;
    shouldScroll?: boolean;
    topOffset?: number;
};

export const UPDATE_SCROLL_GRADIENT_INDICATOR = 'ScrollGradientIndicator:Update';
const SCROLL_BUFFER_IN_PIXELS = 15;
const ScrollGradientIndicator = ({
    children,
    headerElementClassName = 'no-header-element',
    includeTopOffset = true,
    match,
    scrollElementId = 'app-root',
    shouldScroll = true,
    topOffset = 0,
}: IScrollGradientIndicator & RouteComponentProps & { children: JSX.Element }) => {
    const [overlayClasses, setOverlayClasses] = useState<string[]>([]);
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const scrollElement = document.getElementById(scrollElementId);
    const headerElement = document.getElementsByClassName(headerElementClassName)[0] as HTMLElement;

    topOffset = hasValue(headerElement?.offsetHeight)
        ? headerElement?.offsetHeight + topOffset
        : topOffset;

    const handleScroll = useCallback((event: Event) => {
        const root = event.currentTarget as HTMLElement;
        if (root) {
            const rootTop = Math.round(root.scrollTop);
            const atBottom =
                rootTop + SCROLL_BUFFER_IN_PIXELS >= root.scrollHeight - root.offsetHeight;
            const newOverlayClasses = [];
            if (rootTop === 0) {
                newOverlayClasses.push('top');
            }
            if (atBottom) {
                newOverlayClasses.push('bottom');
            }
            setOverlayClasses(newOverlayClasses);
            setIsAtBottom(atBottom);
        }
    }, []);

    const updateGradientIndicator = useCallback(() => {
        handleScroll({ currentTarget: scrollElement } as never);
    }, [scrollElement, handleScroll]);

    useEffect(() => {
        scrollElement?.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', updateGradientIndicator);
        return () => {
            scrollElement?.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', updateGradientIndicator);
        };
    }, [scrollElement, handleScroll, updateGradientIndicator]);

    useEffect(() => {
        EventEmitter.listen(UPDATE_SCROLL_GRADIENT_INDICATOR, updateGradientIndicator);
    }, [updateGradientIndicator]);

    const isRenewal = useMemo(() => match.path.includes('renew'), [match.path]);

    return (
        <GradientIndicator
            className={overlayClasses.join(' ')}
            includeTopOffset={includeTopOffset}
            isAtBottom={isAtBottom}
            isShowingSidebar={isRenewal || isTeamManagementPage}
            shouldScroll={shouldScroll}
            topOffset={topOffset}
        >
            {children}
        </GradientIndicator>
    );
};

export default hot(module)(withRouter(ScrollGradientIndicator));
